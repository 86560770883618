<template>
  <!-- <div :class="['app-wrap', isBig ? 'minwidth' : '']" v-show="!!configId"> -->
  <div :class="['app-wrap', isBig ? 'minwidth' : 'app-wrap-small']" v-show="!!configId">
    <div class="app-wrap-header" v-if="!isIframe&&isComplete">
      <!-- 显示客服数据 -->
      <div class="app-wrap-header-agent" v-show="agentMsg.nickname">
        <img :src="agentMsg.avatar" alt="" :onerror="errorImage">
        <p>{{agentMsg.nickname}}</p>
      </div>
      <div class="app-wrap-header-agent" v-show="!agentMsg.nickname">
        {{agentMsg.defaultText}}
      </div>
      <!-- <div class="app-wrap-header-lang" @mouseleave="mouseleave">
        <span class="app-wrap-header-lang_link" @mouseenter="mouseenter">{{ activeLang }} <i class="el-icon-arrow-down el-icon--right" :class="{'i_active':hoverStatus}"></i></span>
        <div class="app-wrap-header-lang_box" :class="{'app-wrap-header-lang_box-height':hoverStatus}">
          <p v-for="(item, key) in langObj" :key="key" @click="handleCommand(key)">{{ item }}</p>
        </div>
      </div> -->
    </div>
    <div class="app-wrap-con flex relative" >
      <div :class="['app-wrap-con-talk', !questionList.length&&!serviceList.length? 'app-wrap-con-talk_width' : '']">
        <talk :width.sync="width" :configId.sync="configId" :isIframe.sync="isIframe" :theme.sync="themeType" :allow="allow" :service="service" :welcomeTips="welcomeTips" :getSessionData="getSessionData" @getAgent="getAgent" />
      </div>
      <div class="app-wrap-con-jg"></div>
      <!-- {{isBig&&(!!questionList.length||!!serviceList.length)}} -->
      <div :class="['app-wrap-con-seation', isIframe ? 'app-wrap-con-seation_iframe' : '']" v-if="isBig&&(!!questionList.length||!!serviceList.length)">
        <!-- 自助服务模块 -->
        <div :class="['app-wrap-con-seation-service',{'app-wrap-con-seation-service_center' :serviceList.length==1}]" v-if="serviceList.length">
          <div class="app-wrap-con-seation-service-item" v-for="item in serviceList" :key="item.id" @click="clickService(item)">
            <img :src="item.icon_url | filterIcon" width="48" height="48" alt="">
            <p class="ellipsis">{{item.name}}</p>
          </div>
        </div>
        <!-- 常见问题模块 -->
        <div class="app-wrap-con-seation-tab" v-if="!!questionList.length">
          <div class="app-wrap-con-seation-tab_title flex">
            <span @click="chooseTab(index)" :class="{'tab_span_active':curTab==index}" v-for="(item,index) in tabList" :key="item">{{item}}</span>
          </div>
          <div class="app-wrap-con-seation-tab_con" v-if="curTab==0">
            <div class="app-wrap-con-seation-tab_con_item " v-for="(item,index) in questionList" :key="item.title" @click="chooseItem(index)">
              <div class="app-wrap-con-seation-tab_con_item-title pointer">
                <i class="el-icon-arrow-right el-icon--right" :class="{'tab_i_active':itemIndex==index}"></i> {{ item.title }}
              </div>
              <div class="app-wrap-con-seation-tab_con_item-text" :class="{'item_active':itemIndex==index}">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const version = require("element-ui/package.json").version;
import api from "../../fetch/url";
import { setStore } from "@/utils/utils"
import {
  getWelcome,
  getQuestion,
  getSelfService
} from "@/fetch/api";
import { setTimeout } from 'timers';
export default {
  name: "index",
  data() {
    return {
      serviceList: [],  //服务列表
      questionList: [],  //常见问题列表
      welcomeTips:"",    //企业欢迎词
      minwidth:860,      //要显示全部内容的最小宽度
      width:400,         //
      themeType:"",          //主题色
      allow: true,
      activeLang: this.$store.state.activeLang,
      service:"machine",    //服务类型 self:人工 machine：机器

      hoverStatus:false,
      curTab:0,
      tabList:["常见问题"],
      itemIndex:-1,
      configId:"",
      isIframe:false,    //是否来自于嵌套页面
      isComplete:false,

      agentMsg:{
        nickname:"",
        avatar:"",
        status:"offline",
        defaultText:""
      },
      
      errorImage: 'this.src="' + require('@/assets/images/error_img.jpg') + '"',
    };
  },
  computed: {
    langObj() {
      return this.$store.state.langObj;
    },
    // 是否显示全部内容
    // isBig(){
    //   console.log('======', '')
    //   return this.width > this.minwidth &&  document.body.clientWidth>768
    // },
  },
  watch:{
    themeType:{
      immediate:true,
      handler(e){
        window.document.documentElement.setAttribute(  "background-main-color", e );
        window.document.documentElement.setAttribute( "font-main-color", e );
        setTimeout(()=>{
          this.isComplete = true;
        },1000)
      }
    },
    width:{
      immediate:true,
      handler(e){
        if(this.isIframe){
          this.isBig = e > this.minwidth;
        } else {
          // this.isComplete = true;
          this.isBig = e > this.minwidth&&document.body.clientWidth>768;
        }
      }
    },
  },
  filters:{
    filterIcon(e){
      return api.url+"/"+e
    }
  },
  // 引入组件
  components: {
    talk: () => import("@/components/talk"),
  },

  methods: {
    // 获取侧边栏的数据
    getSessionData(configId,tenantId){
      console.error("获取侧边栏的数据")
      this.getSpeech(tenantId);        //获取欢迎词
      // if(this.minwidth>this.width) return;
      this.getQuestInfo(configId,tenantId);    //获取常见问题
      this.getService(configId,tenantId);  //获取自助服务
    },
    /**
     * 获取常见问题
     * return 返回请求结果
     */
    async getQuestInfo(configId, tenantId) {
      getQuestion({ tenantId: tenantId, configId: configId,data:{page:1,size:10} }).then(res=>{
        console.log("=====获取常见问题====", res,res.entity,res.entity.length);
        if(res.code!=200) return;
        this.questionList = res.entity;
        console.error("getQuestInfo",this.questionList)
      })
    },
    /**
     * 获取自助服务
     * return 返回请求结果
     */

    getService(configId, tenantId){
      getSelfService({ tenantId: tenantId, configId: configId,data:{page:1,size:10} }).then(res=>{
        console.log("=====获取自助服务====",res,res.entity,res.entity.length);
        if(res.code!=200) return;
        this.serviceList = res.entity;
      })
    },

    /**
     * 企业欢迎词
     * return 返回请求结果
     */
    getSpeech(tenantId) {
      getWelcome({ tenantId: tenantId }).then((res) => {
        if(res.code!=200) return;
        this.welcomeTips = res.entity.message;
      });
    },

    getStatus(e){
      this.isShow = e
    },
    /**
     * 点击自助服务
     * @params item Object  选择的自助服务
     */
    clickService(item){
      window.open(item.link)
    },

    // ------------------------常见问题模块 开始-------------------------------
    // 切换tab
    chooseTab(index){
      this.curTab = index;
    },
    chooseItem(index){
      if(this.itemIndex==index) return this.itemIndex = -1;
      this.itemIndex = index;
    },

    // ------------------------语言模块 开始-------------------------------
    mouseenter(){
      this.hoverStatus = true;
    },
    mouseleave(){
      this.hoverStatus = false;
    },
    /**
     * 选择语言
     * @params command String  选择的语言
     */
    handleCommand(command) {
      this.$i18n.locale = command;
      this.hoverStatus = false;
      this.language = this.language == "En" ? "cn" : "En";
      this.activeLang = this.langObj[command];
      setStore("user_lang", command);
    },
    // ------------------------语言模块 结束-------------------------------

    // 获取客服信息
    getAgent(e){
      console.error("======获取客服信息=====",e)
      // this.agentMsg = e;
      this.agentMsg = Object.assign({}, this.agentMsg,e)
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/basic.scss";
.app-wrap {
  min-width: 850px;
  overflow-y: hidden;
  &-header {
    height: 68px;
    @include background-main-color($theme_sky);
    &-agent{
      position: absolute;
      left: 20px;
      height: 70px;
      line-height: 70px;
      color: #fff;
      display: flex;
      align-items: center;
      p{
        max-width: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      img{
        height: 30px;
        width: 30px;
        border-radius: 50%;
        margin-right:10px;
      }
    }
    &-lang{
      position: absolute;
      right:20px;
      top: 25px;
      &_link{
        color: #fff;
        cursor: pointer;
        i{
          transition: .3s;
          &.i_active{
            transform: rotate(180deg);
          }
        }
      }
      &_box{
        transition:.3s;
        background-color: #fff;
        box-sizing: border-box;
        line-height: 30px;
        text-align: left;
        margin-top: 10px;
        opacity: 0;
        overflow:hidden;
        min-width: 100px;
        min-height: 80px;
        box-shadow: 5px 5px 20px #ccc;
        &.app-wrap-header-lang_box-height{
          opacity: 1;
        }
        p{
          padding-left: 10px;
          cursor: pointer;
          &:hover{
            @include background-main-color($theme_sky);
            color: #fff;
          }
        }
      }

    }

  }
  &-con {
    width: 100%;
    &-talk {
      text-align: left;
      min-width: 500px;
      width: calc(100vw - 360px);
      &.app-wrap-con-talk_width{
        width:100%;
      }
      &-tips {
        background-color: #fff;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        box-sizing: border-box;
        img {
          vertical-align: middle;
        }
        &-btn{
          width: 58px;
          cursor: pointer;
          @include font-main-color($theme_sky);
        }
      }
    }
    &-seation {
      width: 360px;
      height: calc(100vh - 70px);
      overflow-y: auto;
      background-color: #fff;
      &.app-wrap-con-seation_iframe{
        height: calc(100vh - 10px);
      }
      &-service{
        padding-top: 10px;
        box-sizing: border-box;
        &-item{
          display: inline-block;
          text-align: center;
          font-size: 12px;
          margin: 2% 0;
          width: 23%;
          cursor: pointer;
          img{
            margin-bottom: 8px;
          }
          p{
            width: 100%;
          }
        }
        &.app-wrap-con-seation-service_center{
          .app-wrap-con-seation-service-item{
            margin: 2% auto;
          }
        }
      }
      &-tab{
        &_title{
          height: 42px;
          line-height: 42px;
          padding:0 13px;
          box-sizing: border-box;
          border-bottom: 2px solid rgb(223, 222, 222);
          span{
            display: inline-block;
            padding:0 10px;
            cursor: pointer;
            &.tab_span_active{
              @include font-main-color($theme_sky);
              position: relative;
              &::before{
                content: '';
                position: absolute;
                width: 60px;
                height: 2px;
                @include background-main-color($theme_sky);
                bottom: -2px;
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
        }
        &_con{
          text-align: left;
          padding-top: 10px;
          box-sizing: border-box;
          &_item{
            padding-left: 10px;
            box-sizing: border-box;
            &-title{
              height: 40px;
              line-height: 40px;
              color: #666;
              i{
                transition: .1s;
              }
              .tab_i_active{
                transform: rotate(90deg);
              }
            }
            &-text{
              color: #999;
              padding-left: 22px;
              padding-right: 8px;
              box-sizing: border-box;
              line-height: 23px;
              height: 0;
              transition: .1s;
              overflow:hidden;
              &.item_active{
                min-height: 60px;
                height: auto;
              }
            }
          }
        }
      }
    }
    &-jg{
      width: 1px;
      background-color: #ccc;
    }
  }
}

.app-wrap-small {
  min-width: 350px !important;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
  .app-wrap-con {
    width: 100%;
    &-talk {
      text-align: left;
      min-width: 350px !important;
      width: 100%;
      overflow: hidden;
      border: 0;
      ::v-deep .talk {
        .historyWrap {
          // height: calc(100vh - 200px) !important;
          // padding: 16px 10px !important;
        }
        .historyWrap_iframe{
          height: calc(100vh - 200px) !important;
        }
        .inputWrap-emoji {
          height: 239px;
          width: 418px;
          top: -245px;
        }
      }
    }
  }
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}


</style>
